<template>
  <div>
    <b-datetimepicker
      v-bind="dateRange"
      ref="dateTimePickerElement"
      v-model="datetime"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="field.properties.basic.hasPlaceholder
        ? dateTimeFormatter.formatDateTime(field.properties.basic.placeholder)
        : ''
      "
      :datetime-formatter="dateTimeFormatter.formatDateTime"
      :datetime-parser="dateTimeFormatter.parseDateTime"
      :timepicker="{
        hourFormat,
        disabled: !field.properties.basic.hasTimePicker,
      }"
      :mobile-native="false"
      horizontal-time-picker
      v-on="fieldEvents"
    >
      <template #left>
        <b-button
          label="Jetzt"
          type="is-primary"
          icon-left="clock"
          @click="selectCurrentDateTime"
        />
      </template>
      <template #right>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <b-button
            label="Löschen"
            type="is-danger"
            icon-left="close"
            outlined
            @click="clearDateTime"
          />
          <b-button
            v-if="field.properties.basic.hasTimePicker"
            label="Ok"
            type="is-primary"
            icon-left="check"
            class="mt-5"
            @click="dateTimePickerElement.toggle()"
          />
        </div>
      </template>
    </b-datetimepicker>
  </div>
</template>

<script >
// libs
import { computed, ref, onMounted } from '@vue/composition-api';
import moment from 'moment-timezone';
import { debouncedWatch } from '@vueuse/core';
// others
import { getExpressionParser } from '@nuclicore/core';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const expressionParser = getExpressionParser();
  const props = __props;
  const emit = __ctx.emit;
  const datePickerFormat = computed(() => props.field.properties.basic?.format || 'DD.MM.YYYY');

  // composables specific to this component
  const useDateTimeFormatter = () => {
    const dateTimeFormatter = {};
    dateTimeFormatter.hourFormat = computed(() => datePickerFormat.value?.includes('h') ? '12' : '24');

    /**
     * @param {Date} date 
     */
    dateTimeFormatter.formatDateTime = date => {
      const userTimeZone = moment.tz.guess();
      const localTime = moment.tz(date, userTimeZone);
      return localTime.format(datePickerFormat.value);
    };

    /**
     * @param {string} dateString 
     */
    dateTimeFormatter.parseDateTime = dateString => {
      const userTimeZone = moment.tz.guess();
      let momentObject = moment.tz(dateString, datePickerFormat.value, userTimeZone);
      if (!props.field.properties.basic.hasTimePicker) {
        momentObject = momentObject?.startOf('day');
      }
      return momentObject.toDate();
    };
    return dateTimeFormatter;
  };
  const useDateTimeValidator = () => {
    const dateTimeValidator = {};
    const dateTimeRange = ref({});
    dateTimeValidator.dateRange = computed(() => {
      const range = {};
      const {
        minDate,
        maxDate
      } = dateTimeRange.value;
      if (minDate instanceof Date) {
        range.minDatetime = new Date(minDate);
        range.minDatetime.setHours(0, 0, 0, 0);
      }
      if (maxDate instanceof Date) {
        range.maxDatetime = new Date(maxDate);
        range.maxDatetime.setHours(0, 0, 0, 0);
      }
      return range;
    });
    const computeDateTimeLimit = async (rawValue, isDynamic) => {
      /** @type {Date | null} */
      let datePickerLimit = null;
      if (isDynamic) {
        datePickerLimit = (await expressionParser.parse(rawValue, 'strip')).value;
      } else {
        datePickerLimit = rawValue || null;
      }
      if (datePickerLimit && typeof datePickerLimit === 'string') {
        // converting date string to Date object as required by date picker
        const userTimeZone = moment.tz.guess();
        datePickerLimit = moment.tz(datePickerLimit, userTimeZone).toDate();
      }
      return datePickerLimit;
    };
    debouncedWatch(() => props.values, async () => {
      if (props.field) {
        const {
          properties: {
            validation: {
              isRestrictedFromDynamic,
              restrictedFrom,
              restrictedTo,
              isRestrictedToDynamic
            }
          }
        } = props.field;
        const computedMinDate = await computeDateTimeLimit(restrictedFrom, isRestrictedFromDynamic);
        const computedMaxDate = await computeDateTimeLimit(restrictedTo, isRestrictedToDynamic);
        dateTimeRange.value = {
          ...(computedMinDate ? {
            minDate: computedMinDate
          } : {}),
          ...(computedMaxDate ? {
            maxDate: computedMaxDate
          } : {})
        };
      }
    }, {
      immediate: true,
      deep: true,
      debounce: 500
    });
    return dateTimeValidator;
  };
  const useDateTimePicker = () => {
    const dateTimePicker = {};
    dateTimePicker.dateTimePickerElement = ref(null); // template ref

    const toggleTimePickerVisibility = () => {
      const timePickerElement = dateTimePicker.dateTimePickerElement.value?.$el.querySelector('.timepicker');
      if (timePickerElement) {
        if (props.field.properties.basic.hasTimePicker) {
          timePickerElement.style.display = 'block';
        } else {
          timePickerElement.style.display = 'none';
        }
      }
    };
    onMounted(() => {
      toggleTimePickerVisibility();
    });
    return dateTimePicker;
  };

  // use composables
  const dateTimeFormatter = useDateTimeFormatter();
  const dateTimeValidator = useDateTimeValidator();
  const dateTimePicker = useDateTimePicker();

  // unwrap nested refs and computed (so that script setup can unwrap inner value to template)
  const {
    hourFormat
  } = dateTimeFormatter;
  const {
    dateRange
  } = dateTimeValidator;
  const {
    dateTimePickerElement
  } = dateTimePicker;
  const datetime = computed({
    get() {
      const inputDate = props.values[props.field.id];
      if (inputDate) {
        return typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
      }
      return null;
    },
    set(newDate) {
      if (newDate && !props.field.properties.basic.hasTimePicker) {
        dateTimePickerElement.value.toggle();
      }
      if (dateTimePickerElement.value) {
        // @NOTE: this is a workaround for updating inner input value inside datepicker as buefy's input have issues with value updation while manually typing same dates
        const innerInputComponentInstance = dateTimePickerElement.value?.$children[0]?.$children[0]?.$children?.find(child => child.type === 'text');
        if (innerInputComponentInstance) {
          innerInputComponentInstance.newValue = dateTimeFormatter.formatDateTime(newDate);
        }
      }
      emit('update', {
        value: newDate
      });
    }
  });
  const selectCurrentDateTime = () => {
    let currentDate = moment();
    if (!props.field.properties.basic.hasTimePicker) {
      currentDate = currentDate.startOf('day');
    }
    datetime.value = currentDate.toDate();
    dateTimePickerElement.value.toggle();
  };
  const clearDateTime = () => {
    datetime.value = null;
  };
  return {
    dateTimeFormatter,
    hourFormat,
    dateRange,
    dateTimePickerElement,
    datetime,
    selectCurrentDateTime,
    clearDateTime
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.datepicker-footer{
    .level{
        display: flex;
        flex-direction: column;
        .level-item {
            margin-right: 0 !important;
        }
    }
    button {
        width: fit-content;
    }
}
</style>
